import './styles.css';

export default function NavBar() {
    return (
        <nav id="navbar" className="navbar">
            <ul>
                <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                <li><a className="nav-link scrollto" href="#about">About</a></li>
                {/*<li><a className="nav-link scrollto" href="#mission">Mission</a></li>*/}
                <li><a className="nav-link scrollto" href="#impact">Impact</a></li>
                <li className="dropdown"><a className="nav-link scrollto" href="#portfolio"><span>Product Portfolio</span><i className="bi bi-chevron-down"></i></a>
                    <ul>
                        <li><a onClick={() => window.navTo('plant')} href="#portfolio-plant">Plant Therapeutics</a></li>
                        <li><a onClick={() => window.navTo('animal')} href="#portfolio-animal">Treatment of animal diseases</a></li>
                        <li><a onClick={() => window.navTo('human')} href="#portfolio-human">Human therapeutics for infectious diseases</a></li>
                        <li><a onClick={() => window.navTo('memory')} href="#portfolio-memory">Treatment of memory and brain disorders</a></li>
                    </ul>
                </li>
                <li><a className="nav-link scrollto" href="#team">Team</a></li>
                {/*<li><a className="nav-link scrollto " href="#facility">Facility</a></li>*/}
                <li><a className="nav-link scrollto" href="#faq">F.A.Q</a></li>
                <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
    );
}