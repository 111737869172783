import Leaders from './Leaders';
import Operation from './Operation';
import ProductPipeline from './ProductPipeline';
import Tracking from './Tracking';
import './styles.css';

export default function Team() {
    return (
        <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Team</h2>
                    <h3>Our <span>Team</span></h3>
                    <p>We strongly believe in teamwork spirits and we do have a highly qualified team as well.</p>
                </div>

                <div className="features">
                    <ul className="nav nav-tabs row  g-2 d-flex">
                        <li className="nav-item col-4">
                            <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-leaders">
                                <h4>Leaders</h4>
                            </a>
                        </li>
                        <li className="nav-item col-4">
                            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-operation">
                                <h4>Operations, Financial and Legal</h4>
                            </a>
                        </li>
                        <li className="nav-item col-4">
                            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-tracking">
                                <h4>IT and Marketing</h4>
                            </a>
                        </li>
                        {/*<li className="nav-item col-4">
                            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-pipeline">
                                <h4>Product Pipeline</h4>
                            </a>
                        </li>*/}
                    </ul>

                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="tab-leaders">
                            <div className="row">
                                <Leaders />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tab-operation">
                            <div className="row">
                                <Operation />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tab-tracking">
                            <div className="row">
                                <h4>By SandboxLife®</h4>
                                <p>Disease tracking and diagnosis are followed by developing protein therapeutic solutions based upon precision design.  Federal and industry funding allowed completing R&D for protein protein therapies, which are now being marketed.</p>
                                <Tracking />
                            </div>
                        </div>
                        {/*<div className="tab-pane fade" id="tab-pipeline">
                            <div className="row">
                                <ProductPipeline />
                            </div>
                        </div>*/}
                    </div>
                </div>

            </div>

            <br /><br />
        </section>
    );
}