import Animal from './Animal';
import Human from './Human';
import Memory from './Memory';
import Plant from './Plant';
import './styles.css';

export default function Portfolio() {
    return (
        <section id="portfolio" className="portfolioxx">
            <div className="container" data-aos="fade-up">

                <br /><br />
                <div className="section-title">
                    <h2>Product Portfolio</h2>
                    <h3>Check our <span>Product Portfolio</span></h3>
                    <p>Navigate through our portfolio to learn more about what we do</p>
                </div>

                <div className="features" data-aos="fade-up" data-aos-delay="200">
                    <ul className="nav nav-tabs row g-2 d-flex">
                        <li className="nav-item col-3">
                            <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-plant">
                                <h4>Plant</h4>
                            </a>
                        </li>
                        <li className="nav-item col-3">
                            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-animal">
                                <h4>Animal</h4>
                            </a>
                        </li>
                        <li className="nav-item col-3">
                            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-human">
                                <h4>Human</h4>
                            </a>
                        </li>
                        <li className="nav-item col-3">
                            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-memory">
                                <h4>Memory</h4>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="tab-plant">
                            <div className="row">
                                <Plant />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tab-animal">
                            <div className="row">
                                <Animal />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tab-human">
                            <div className="row">
                                <Human />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tab-memory">
                            <div className="row">
                                <Memory />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}