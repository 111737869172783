import { useMemo } from "react";
import Member from "./Member";

export default function Tracking() {
    const members1 = useMemo(() => [
        {
            image: 'vanessa.jpg',
            name: 'Vanessa Valore',
            position: 'Co-founder, CEO',
            position_old: 'Lead, strategic alliances/ operations/ communication',
            bio_old: `Ms. Valore attended Professional Children’s School in New York City and later Parsons School of Design and The New School for Social Research. As a student of the Santa Fe College of Arts and Design, she completed her degree in a customized program abroad in Southern Spain, North Africa, and Italy. In her professional career, Ms.Valore has been an entrepreneur and an integrated/strategic marketing specialist. She founded Valore Ventures in 1996, and as the president oversaw three divisions. Ms.Valore founded <a href="http://www.sandboxlife.com" target="_blank" rel="noreferrer"><strong>SandboxLife®</strong></a>, a technology company focusing on the classification of behavioral science with an element of gamification. She delivers lectures specialized in each company she has consulted for with an emphasis on technology and New Media. Ms.Valore was on two boards for former New Mexico Governor Bill Richardson – she was chair for the Design Board in Santa Fe, NM and also was on the Film and New Media Advisory Board. She has focused on fund raising first through the hospitality industry, and later on Technology start-ups and cross platforms of New Media. Ms.Valore will be responsible for market analysis, strategic planning, and branding of the company products. Finally, she will be assisting in raising funds for maturation and regulatory approval of the products.`,
            bio: `Ms. Valore founded <a href="http://www.sandboxlife.com" target="_blank" rel="noreferrer"><strong>SandboxLife®</strong></a>, a technology company focusing on the classification of behavioral science with an element of gamification. She worked with Bill Richardson (the former NM governor).  She chaired the Design Board in Santa Fe, NM and served as a member on the Film and New Media Advisory Board.  She has been successful in raising funds for various start-ups including the hospitality industry, Technology start-ups and new cross platforms media. For Diverse Vitality, Inc., Ms. Valore will be responsible for market analysis, strategic planning, and branding of the company products. Finally, she will be leading executing the company mission and expanding the product portfolio to meet the needs of public health and agriculture.`
        },
        {
            image: 'goutam.jpg',
            name: 'Goutam Gupta, Ph.D.',
            position: 'Founder',
            bio_old: `Dr. Gupta obtained his Ph.D. from the Indian Institute of Science, Bangalore, India (thesis title: conformational flexibility of DNA). He joined Los Alamos National Laboratory in the early 90’s where he applied expertise in structural biology to explain how unusual structures formed by DNA repeats in the human genome leads to onset and progression of several neurological disorders. At LANL he also initiated his research on AIDS to demonstrate how small changes in the sequence/structure of a surface antigen allows HIV to escape host immune pressure. Since 9/11, Dr. Gupta started working on understanding the biology of several biothreat agents and developing therapy based upon enhancing the host immunity, which subsequently applied to multiple plant and human pathogens, which are of agricultural and public health relevance. He has been supported by federal (NIH, DARPA, USDA) and industry grants. Dr. Gupta served as the co-director of the LANL Bio-Security Center. He joined the New Mexico Consortium in 2016 as a Senior Research Scientist and initiated development of therapeutic products for plant and human diseases and the success in that area is the genesis of Diverse Vitality, LLC.`,
            bio: `Dr. Gupta was jointly employed at Diverse Vitality and the New Mexico Consortium (NMC).  Before joining the NMC in 2016, he worked as a lead scientist at Los Alamos National Laboratory (LANL).   His research focused on the biology of infectious diseases in humans and plants and neurological disorders.  At the NMC, he initiated development of novel non-toxic treatment of infectious diseases in plants and humans by engineering novel host immunity.  The same approach was also applicable to Alzheimer’s Disease treatment by clearance amyloid plaques. Publication of patents based on Dr. Gupta’s discoveries led to the founding of Diverse Vitality, Inc.`
        },
        {
            image: 'saad.jpg',
            name: 'Saad Khayyat',
            position: 'Senior IT Advisor',
            bio: ``
        },
        {
            image: 'ezz.jpg',
            name: 'Ezz Khayyat',
            position: 'Senior Software Engineer',
            bio: `Ezz Khayyat is a well experienced Senior Software Engineer, so addicted to computers and technology. He holds a B.A. degree in Computer Science.
            In addition to his professional experience in software, Ezz likes to work in environments where challenges and success are high. He believes in teamwork spirit, and he also  respects other people's opinions. 
            He always eager and continuously looking forward to enhance his career vision and skills, and to expand the horizon of his future.`,
        }
    ], []);

    const members2 = useMemo(() => [
        {
            image: 'arthur.jpg',
            name: 'Arthur Martinez III',
            position: '',
            bio: ``
        },
        {
            image: 'connor.jpg',
            name: 'Connor Valore-Kemmerer',
            position: '',
            bio: ``
        },
        {
            image: 'duncan.jpg',
            name: 'Duncan Valore-Kemmerer',
            position: '',
            bio: ``
        },
        {
            image: 'james.jpg',
            name: 'James Fadigan, Ph.D.',
            position: '',
            bio: ``
        },
    ]);

    return (
        <>
            {/*<h5>Tracking</h5>*/}
            <div className="row">
                {members1.map((member, idx) => <Member key={idx} data={member} />)}
            </div>

            <div className="my-4" />
            <h4>Advisory Team</h4>
            <div className="row">
                {members2.map((member, idx) => <Member key={idx} data={member} />)}
            </div>
        </>
    );
}