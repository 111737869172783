import './styles.css';

export default function Contact() {
    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Contact</h2>
                    <h3><span>Contact Us</span></h3>
                    <p>For any inquiries, questions or additional information, do not hesitate to reach us. All of your communications are welcomed.</p>
                </div>

                <div className="row" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-6">
                        <div className="info-box mb-4">
                            <i className="bx bx-map"></i>
                            <h3>Our Address</h3>
                            <p>933 San Mateo Blvd NE #500-227</p>
                            <p>Albuquerque NM 87108</p>
                            {/*<p>933 San Mateo Blvd #500-227</p>
                            <p>ABQ NM 87108</p>*/}
                            <p>&nbsp;</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="info-box mb-4">
                            <i className="bx bx-envelope"></i>
                            <h3>Email Us</h3>
                            <p><a href="mailto:goutam@diversevitality.com">goutam@diversevitality.com</a></p>
                            <p><a href="mailto:vanessa.valore@diversevitality.com">vanessa.valore@diversevitality.com</a></p>
                            <p><a href="mailto:contact@diversevitality.com">contact@diversevitality.com</a></p>
                            <p>Please CC <a href="mailto:valoreventures@gmail.com">valoreventures@gmail.com</a></p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="info-box  mb-4">
                            <i className="bx bx-phone-call"></i>
                            <h3>Call Us</h3>
                            <div style={{ display: 'grid', gridTemplateColumns: 'max-content max-content', columnGap: 12, justifyContent: 'center', justifyItems: 'start' }}>
                                {/*<span style={{ whiteSpace: 'nowrap' }}>Steve Buelow</span><a style={{ whiteSpace: 'nowrap' }} href="tel:+1-505-209-2211">+1 505 209 2211</a>*/}
                                <span style={{ whiteSpace: 'nowrap' }}>Goutam Gupta</span><a style={{ whiteSpace: 'nowrap' }} href="tel:+1-505-209-2211">+1 505 209 2211</a>
                                <span style={{ whiteSpace: 'nowrap' }}>Vanessa Valore</span><a style={{ whiteSpace: 'nowrap' }} href="tel:+1-505-209-2211">+1 505 209 2211</a>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row" data-aos="fade-up" data-aos-delay="100">

                    <div className="col-lg-6 ">
                        <iframe title="map" className="mb-4 mb-lg-0" src="https://maps.google.com/maps?q=Santa%20Fe%20Business%20Incubator,%203900%20Paseo%20del%20Sol,%20Santa%20Fe,%20NM%2087507,%20USA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" style={{ border: 0, width: '100%', height: '384px' }} allowFullScreen></iframe>
                    </div>

                    <div className="col-lg-6">
                        <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="row">
                                <div className="col form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div className="col form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit">Send Message</button></div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    );
}