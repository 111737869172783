import { useMemo } from 'react';
import Item from './Item';
import './styles.css';

export default function Facility() {

    const data = useMemo(() => [
        /*{
            category: 'office',
            image: 'office/office01.jpg',
            title: 'Biolab',
            details: 'Location of our new biolab, in the Trades and Advanced Technology Center (TATC) at Santa Fe Community College (SFCC)'
        },*/
        {
            category: 'office',
            image: 'office/office02.jpg',
            title: 'Board Room',
            details: 'The conference room at the SFBI'
        },
        {
            category: 'office',
            image: 'office/office03.jpg',
            title: 'Board Room',
            details: 'The conference room at the SFBI'
        },
        /*{
            category: 'lab',
            image: 'lab/elena.jpg',
            title: 'Lab',
            details: 'Elena'
        },
        {
            category: 'lab',
            image: 'lab/narratam.jpg',
            title: 'Lab',
            details: 'Narratam'
        },
        {
            category: 'lab',
            image: 'lab/mikhail.jpg',
            title: 'Lab',
            details: 'Mikhal'
        },

        {
            category: 'lab',
            image: 'lab/liza.jpg',
            title: 'Lab',
            details: 'Liza'
        },*/
        /*{
            category: 'office',
            image: 'office/office04.jpg',
            title: 'Board Room',
            details: 'The conference room at the SFBI'
        },*/
        /*{
            category: 'lab',
            image: 'office/facility02.jpg',
            title: 'Main BSL-1',
            details: 'Our main BSL-1 space downstairs'
        },
        {
            category: 'lab',
            image: 'lab/facility03.jpg',
            title: 'Secondary BSL-1',
            details: 'A secondary BSL-1 space upstairs'
        },
        {
            category: 'lab',
            image: 'lab/facility04.jpg',
            title: 'Additional BSL-1',
            details: 'Additional BSL-1 space upstairs'
        },
        {
            category: 'lab',
            image: 'lab/facility06.jpg',
            title: 'Human cell work BSL-2',
            details: 'Upstairs BSL-2 space for conducting human cell work'
        },
        {
            category: 'lab',
            image: 'lab/facility05.jpg',
            title: 'Human cell work BSL-2',
            details: 'Upstairs BSL-2 space for conducting human cell work'
        },
        {
            category: 'lab',
            image: 'lab/facility09.jpg',
            title: 'Ultracentrifuges',
            details: 'Ultracentrifuges, autoclave, and other large equipment kept in our lab downstairs'
        },*/
        /*{
            category: 'greenhouse',
            image: 'greenhouse/greenhouse01.jpg',
            title: 'Greenhouse',
            details: 'An on-site greenhouse located next to the TATC where we house plants for construction, propagation, and maintenance of plants'
        },
        {
            category: 'greenhouse',
            image: 'greenhouse/greenhouse02.jpg',
            title: 'SFCC greenhouse',
            details: 'Transgenic tobacco expressing therapeutic proteins are housed in the SFCC greenhouse'
        },
        {
            category: 'computation',
            image: 'computation/computation01.jpg',
            title: 'Computers',
            details: 'High performance computer clusters'
        },*/
    ], []);

    return (
        <section id="facility" className="facility">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Facility</h2>
                    <h3>Check our <span>Facility</span></h3>
                    <p>Learn more about our facility through our interactive gallery</p>
                </div>

                <p>
                    Goutam Gupta has been awarded USDA-NIFA grants for developing treatment of HLB in citrus.
                    These are collaborative projects which involve the USDA-ARS Laboratory, Colorado State University (CSU), Texas A&amp;M University, Cornell University, IR-4/Rutgers University.
                    Dr. Gupta and his technical team have laboratories at the Santa Fe Community College (SFCC).
                    This includes BSL-1/2 laboratories as well as a greenhouse.
                </p>

                <p>
                    Dr. Gupta and his team have access to the NMC’s high performance computing facilities.
                    This enables them to perform computer design of host-derived peptides/proteins, laboratory and activity/toxicity analysis.
                </p>

                {/*<p>
                    One key part of the product pipeline is to design transgenic tobacco/tomato/grape lines
                    expressing proteins that kill/clear the infecting pathogens.
                    Note that transgenic tobacco is also the source of extracting/purifing the therapeutic
                    proteins activities of which can then be tested <i>in vitro</i>.
                    The IP developed under federal and industry grants are owned by the NMC since Goutam
                    and his product development team are employed by the NMC through the grants awarded to Goutam.
                </p>*/}

                <div className="row" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="facility-flters">
                            {/*<li data-filter="*" className="filter-active">All</li>*/}
                            <li data-filter=".filter-office" className="filter-active">Corporate</li>
                            {/*<li data-filter=".filter-lab">Lab</li>
                            <li data-filter=".filter-greenhouse">Greenhouse</li>
                            <li data-filter=".filter-computation">Computation</li>*/}
                        </ul>
                    </div>
                </div>

                <div className="row facility-container" data-aos="fade-up" data-aos-delay="200">
                    {data.map((rec, idx) => <Item key={idx} data={rec} />)}
                </div>

            </div>
        </section>
    );
}
