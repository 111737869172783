import { useMemo } from 'react';
import Item from './Item';
import './styles.css';

export default function FAQ() {
    const data = useMemo(() => [
        {
            title: 'Why did we coin the name "Diverse Vitality"?',
            body: 'The company name symbolizes our mission for providing  biological solutions to a diverse set of diseases ranging from infectious diseases in plants/animals/humans and memory/brain disorders in humans.'
        },
        {
            title: 'How do the diverse biological solutions originate from the common theme or how are they interconnected?',
            body: 'Our products are designed by a platform technology that makes the host stronger via enhanced immunity thereby enabling the host not only to fight disease by eliminating the root causes but also to maintain good health (i.e., thrive beyond survive).'
        },
        {
            title: 'What are the key determinants of marketability for therapeutic products for plant/animal/human diseases and brain/memory disorders?',
            body: 'Efficacy (how well the products work), Safety (are they toxic to plant, human, and environment), Affordability (can the sellers make reasonable profit by selling at a price that the buyers can afford)'
        },
        {
            title_old: 'How does the company rank the importance of plant/animal/human infectious diseases in terms of their (i) relevance to public health and agriculture and (ii) economic impact?',
            title: 'How does the company rank the importance of plant/animal/human diseases?',
            body: 'In terms of their (i) relevance to public health, biosecurity, and agriculture, (ii) economic impact, and (iii) availability of efficacious, safe, and affordable solutions or lack thereof.'
        },
        {
            title: 'How does the company examine the exact origin of memory and brain disorders in humans, thereby guiding the development of precision therapy?',
            body: 'Genetic and environmental factors are examined to pinpoint what gene, protein, or cell dysfunction correlates with a particular memory or brain disorder and then a therapy is developed to correct precisely that particular abnormality in the brain (e.g., amyloid plaque in Alzheimer\'s Disease). '
        },
        {
            title: 'How did the company evaluate the % completeness in the maturity of the therapeutic products for plant/animal/human infectious diseases or memory/brain disorders?',
            body: 'Based upon whether the product is protected by IP and approved by the EPA/FDA and if the cost of the product is reasonable for marketing.'
        },
        {
            title: 'How does the company measure its growth potential?',
            body: 'A platform technology allows providing solutions to multiple diseases, which will attract federal and industry funding for product development and private/corporate investments for commercialization.'
        },
    ], []);

    return (
        <section id="faq" className="faq section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>F.A.Q</h2>
                    <h3>Frequently Asked <span>Questions</span></h3>
                    <p>You got questions? No problem! We have answers right away for the most frequently asked questions.</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <ul className="faq-list">
                            {data.map((rec, idx) => <Item key={idx} seq={idx + 1} data={rec} />)}
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    );
}