export default function Animal() {
    return (
        <section id="portfolio-animal" className='pt-0'>
            <h5>Treatment of animal diseases</h5>
            <br />

            <p>
                The platform technology for the treatment of viral and bacterial diseases in plants and humans is equally applicable to animal diseases.
                Due to species jump (from animals to humans) animal viruses and bacteria pose emerging threats to public health and biosecurity (e.g., swine flu).
                We will expand our portfolio as appropriate.
            </p>
        </section>
    );
}