export default function Plant() {
    return (
        <section id="portfolio-plant" className='pt-0'>
            <h5>Plant Therapeutics</h5>

            <p data-aos="fade-left" data-aos-delay="100">
                Bacterial, viral, and fungal diseases pose a severe threat to US agriculture and the economy.
                Our platform technology offers therapies for these diseases by targeting the pathogens and clearing them.
                The initial foci are on: Pierce’s Disease (PD) in grapes caused by the vector borne <i>Xylella fastidiosa</i> and
                Huanglongbing (HLB) in citrus also caused by the vector borne <i>C</i>andidatus Liberibacter asiaticus (<i>C</i>Las).
            </p>

            <div className="d-flex flex-wrap justify-content-around" data-aos="fade-up" data-aos-delay="100">
                <img src="assets/img/portfolio/plant01.png" className="m-1" style={{ width: '100%', maxWidth: 600, height: '100%', border: '1px solid black' }} alt="CLas" />
                <img src="assets/img/portfolio/plant02.png" className="m-1" style={{ width: '100%', maxWidth: 600, height: '100%', border: '1px solid black' }} alt="Xylella fastidiosa" />
            </div>

            <br /><br />

            <div className="d-flex flex-column flex-wrap">
                <p data-aos="fade-right" data-aos-delay="100">
                    Two classes of plant (i.e., grape and citrus) derived therapeutics are considered.
                </p>

                <div className="d-flex flex-wrap justify-content-center" data-aos="fade-up" data-aos-delay="100">
                    <img src="assets/img/portfolio/plant03.png" className="m-1" style={{ width: '100%', maxWidth: 900, height: '100%', border: '1px solid black' }} alt="Two classes of plant" />
                </div>
            </div>

            <br /><br />

            <p data-aos="fade-left" data-aos-delay="100">
                Not only do the two therapeutics clear the disease-causing pathogens
                but they also enhance the plant immunity during infection and protect the beneficial plant microbiomes.
                Thus, the plants survive the pathogen attack, remain healthy, and show vitality.
                This multifunctional nature of our therapy is the unique feature that makes it desirable to the end users.
            </p>


            <div className="d-flex flex-wrap justify-content-center" data-aos="fade-right" data-aos-delay="100">
                <img id="Picture 5" src="assets/img/portfolio/plant04.png" style={{ width: '100%', maxWidth: 1024, height: '100%', border: '0px solid black' }} alt="Plant Defense" />
            </div>

            <br />

            <p data-aos="fade-left" data-aos-delay="100">
                The <span>&alpha;/&beta;</span> peptides and chimeras linking them with a binding domain (BD)
                can be delivered by foliar spray.
                For PD treatment, the <span>&alpha;/&beta;</span> peptides are sprayed mixed with a
                surfactant that facilitates penetration of the grape leaves.
                For HLB treatment, the peptides are sprayed on laser etched citrus leaf surfaces for
                efficient delivery of the peptide to the phloem, the site of <i>C</i>Las colonization.

                However, the cost-effective and efficient delivery of the chimera will involve plant exosomes.
                Specifically, exosomes (of about 140 nm diameter) will be extracted from grape or citrus juices.
                A plasmid carrying the chimera gene will be encapsulated in the exosomes.
                The surface of the exosome will be decorated with basic peptides to facilitate leaf penetration.
                Finally, the decorated and plasmid encapsulated exosomes will be sprayed to deliver the chimera protein <i>in planta</i> via transient expression.
                The exosomes will offer a non-GMO route with the efficiency of a GMO solution.
                The treatment is effective within a few months rather than the 10-20 years needed for the GMO solution.
            </p>

            <div className="d-flex flex-wrap justify-content-around" data-aos="fade-up" data-aos-delay="100">
                <img src="assets/img/portfolio/plant05.png" className="m-1" style={{ width: '100%', maxWidth: 500, height: '100%', border: '0px solid black' }} alt="Spraying" />
                <img src="assets/img/portfolio/plant06.png" className="m-1" style={{ width: '100%', maxWidth: 700, height: '100%', border: '0px solid black' }} alt="Exosome Spray" />
            </div>
        </section>
    );
}