import './styles.css';

export default function About() {
    return (
        <section id="about" className="about section-bgx">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>About</h2>
                    <h3>Find Out More <span>About Us</span></h3>
                    <p>Who we are...?</p>
                </div>

                <div className="row">
                    <div className="pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-right" data-aos-delay="100">
                        <p>
                            The scientists on our team have a long track record in obtaining funding from NIH/DoD/USDA and industries.
                            This funding has laid the foundation for developing therapies for plant, animal, and human diseases.
                            These therapies directly target the root causes of the diseases thus eliminating them from the host.
                            We initially focused on bacterial and viral pathogens that cause deadly plant/animal/human diseases of agricultural and public health relevance.
                        </p>
                        <p>
                            We also began working on memory and brain disorders in humans caused by damage due to protein misfolds and aggregates such as those encountered in Alzheimer’s Disease.
                            All our therapies involve a unique platform technology that enhances host immunity to clear pathogens and damages to deliver a diverse <a href="#portfolio">product portfolio</a>.
                        </p>

                        <br />

                        <div className="d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                            <img
                                src="assets/img/portfolio/mission.png"
                                className="img-fluid xglightbox"
                                style={{ width: '100%', maxWidth: '768px' }}
                                alt="" />
                        </div>

                        <br />

                        <p data-aos="fade-left" data-aos-delay="100">
                            Having reached a stage of maturity in product development, we formed our company Diverse Vitality LLC to offer effective, safe, affordable, and user-friendly therapeutic products for infectious diseases in plants, animals, and humans as well as for memory and brain disorders.
                            <br />
                            We have assembled a <a href="#team">multi-disciplinary team</a> for research and product development.
                            The company led by the founder, Dr. Goutam Gupta, Steven Buelow, the CEO with decades of experience in running an operation, and Vanessa Valore, an expert in building strategic partnerships/operations/communication.
                            Other team members, legal experts to protect our business and intellectual properties, and a team for building product pipeline.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    );
}