import classNames from "classnames"

export default function Item({ data }) {
    return (
        <div className={classNames("col-lg-4 col-md-6 facility-item", `filter-${data.category}`)}>
            <img src={`assets/img/facility/${data.image}`} className="img-fluid" alt="" />
            <div className="facility-info">
                <h4>{data.title}</h4>
                <a href={`assets/img/facility/${data.image}`} data-gallery="facilityGallery" className="facility-lightbox preview-link" title={data.details}><i className="bx bx-zoom-in"></i></a>
                {/*<a href="facility-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>*/}
            </div>
        </div>
    );
}