import FeaturedServices from '../FeaturedServices/FeaturedServices';
import Tree from '../Tree/Tree';
import './styles.css';

export default function Hero() {
    return (
        <section id="hero" className="d-flex align-items-center">
            <div className="tree-grid w-100" style={{ /*marginTop: '500px', marginLeft: 25*/ }}>
                <div className="m-3" data-aos="zoom-out" data-aos-delay="100">
                    <h1 style={{ fontSize: '2em' }}>Welcome to <span>Diverse Vitality</span></h1>
                    <h1 style={{ fontSize: '1.2em' }}><span>Vitality</span>: The power giving continuance of life, present in all living things.</h1>
                    <br />
                    <h2 style={{ fontSize: '1.4em' }}>We are a team of multi-disciplinary experts offering solutions for better <span style={{ color: '#000', fontWeight: 'bold' }}>Life</span></h2>

                    <FeaturedServices />

                    <div className="d-flex">
                        <a href="#about" className="btn-get-started scrollto">Get Started</a>
                        <div href="#" hrefx="/assets/videos/DiverseVitality.mp4" className="xglightbox btn-watch-video" style={{ cursor: 'not-allowed' }}>
                            <i className="bi bi-play-circle"></i>
                            <span>Videos are coming soon</span>
                        </div>
                    </div>
                </div>
                <div className="tree m-3" data-aos="fade-left" data-aos-delay="500">
                    <h4 align="center">
                        <span style={{ color: '#5A8D60' }}>Thrive Beyond Survive</span>
                    </h4>
                    <Tree id='tree' />
                    <h4 align="center" style={{ marginTop: -60 }}>
                        <span style={{ color: '#AE7750' }}>Emerging and<br />Chronic Threats</span>
                    </h4>
                </div>
            </div>
            <div id='tooltip' style={{ position: 'absolute', backgroundColor: '#222222E0', color: '#50AE5A', borderRadius: 5, display: 'none' }}>
                <div id='tooltip-label' style={{ margin: 5 }}></div>
            </div>
        </section>
    );
}