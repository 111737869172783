import { useEffect } from 'react';
import { ReactComponent as TreeSvg } from '../../img/tree.svg';
import $ from 'jquery';
import './styles.css';

export default function Tree() {
    const getLeafClass = el => Array.from(el.classList).find(cls => cls.startsWith('leaf-'));
    //const getLeafClasses = el => Array.from(el.classList).filter(cls => cls.startsWith('leaf'));

    useEffect(() => {
        //onoff($('#tree #leaf-*')[0]);
        $('#tree path, #tree ellipse').each((idx, el) => {
            if (el.id.startsWith('leaf')) {
                $(el).on('mouseenter', e => {
                    const leafClass = getLeafClass(el);
                    const id = leafClass.replace('leaf-', '');
                    //console.log('enter', Array.from(el.classList))
                    //console.log('enter', getLeafClasses(el), getLeafClass(el));
                    $(`#${leafClass}, .${leafClass}.cls-2`).css('fill', '#A0E040');
                    const label = id ? id.charAt(0).toUpperCase() + id.slice(1) : '';
                    $('#tooltip-label').text(label);
                    $('#tooltip').show();
                    //el.style.fill = '#f90';
                }).on('mouseleave', e => {
                    const leafClass = getLeafClass(el);
                    //console.log('leave', e);
                    //el.style.fill = '';
                    $(`#${leafClass}, .${leafClass}.cls-2`).css('fill', '');
                    $('#tooltip').hide();
                }).on('click', e => {
                    const leafClass = getLeafClass(el);
                    const id = getLeafClass(el).replace('leaf-', '');
                    $(`#${leafClass}, .${leafClass}.cls-2`).css('fill', '#f15');
                    console.log('click: ' + id);
                    //window.location.hash = "#portfolio-" + id;
                    window.navTo(id);
                });
            }
        });

        document.addEventListener('mousemove', e => {
            let tooltip = document.getElementById('tooltip');
            tooltip.style.left = e.pageX + 'px';
            tooltip.style.top = e.pageY - 100 + 'px';
        });
    }, []);

    return <TreeSvg id='tree' />;
}