import './styles.css';

export default function Supporters() {
    return (
        <>
            <section id="supporters" className="supporters section-bgx">
                <div className="container" data-aos="zoom-in">

                    <div className='section-title mt-5'>
                        <h3>Supported By</h3>
                    </div>

                    <div className="row justify-content-center">

                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/supporters/USDA.png" className="img-fluid" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/supporters/dod.png" className="img-fluid" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/supporters/nih.jpeg" className="img-fluid" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/supporters/Citrus-Industry.png" className="img-fluid" alt="" />
                        </div>

                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img src="assets/img/supporters/wine-industry.png" className="img-fluid" alt="" />
                        </div>


                    </div>

                </div>
            </section>
        </>
    );
}