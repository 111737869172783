import './styles.css';

export default function Impact() {
    return (
        <section id="impact" className="impact section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Impact</h2>
                    <h3>The Impact on <span>Agriculture</span> and <span>Public Health</span></h3>
                    <p>Learning about the impact of diseases helps understand why we are here</p>
                </div>

                <div className="row">
                    <div className="" /*data-aos="fade-right" data-aos-delay="100"*/>
                        <p data-aos="fade-right" data-aos-delay="100">
                            Host derived peptides and proteins are designed to mitigate the <b>danger</b> due to pathogenic infections in plants, animals, and humans;
                            and <b>damage</b> due to misfolds and aggregates of endogenous proteins in brain and memory disorders.
                            Our design strategy produces peptides and proteins with high activity on their targets, thereby requiring only a low dose of application.
                            The choice of host proteins and the use of low doses for therapy minimizes the risk of host toxicity.
                            Also, their application at low doses makes the use of the peptides and proteins cost-effective.
                            Above all, the non-GMO aspect of our therapy makes it appealing to the end users.
                        </p>

                        <p data-aos="fade-left" data-aos-delay="200">
                            We initially targeted Pierce’s Disease (PD) in grapes and Huanglongbing (HLB) in citrus.
                            Currently no cures for these diseases exist and the economic impact is quite severe on the grape and citrus industries in the US and worldwide.
                            The market potential and profitability of our therapeutic products are quite high since they are efficacious, safe, cost-effective, and user-friendly.
                        </p>

                        <p data-aos="fade-right" data-aos-delay="300">
                            COVID is our first infectious disease target for humans.
                            We understand that the field is quite crowded.
                            However, we offer several unique advantages.
                            We designed a host-based protein ligand that binds to the virus with high affinity and prevents it from binding to host lung cells, which is the first step in viral pathogenesis.
                            This protein ligand also contains a functional domain hFc that facilitates rapid viral clearance by effector cells.
                            This same protein ligand can be used for rapid and sensitive detection and characterization of several virus subtypes by sequencing in a laboratory set-up.
                            Finally, the dual-purpose protein ligand will have a special market in disease management.
                        </p>

                        <p data-aos="fade-left" data-aos-delay="400">
                            For brain and memory disorders, we are targeting the plaque formation in <b>Alzheimer’s disease (AD)</b>.
                            Note that there has been a significant investment in AD therapies using several small molecules including steroids.
                            Success in such therapies has not been promising.
                            Therefore, we took a different approach.
                            We identified two host proteins with reduced expression in patients diagnosed with AD, namely, a protein that inhibits the amyloid plaque formation and another protein that cleaves amyloid peptides.
                            We re-engineered these two proteins to increase their activity and joined them in a chimera for facilitating synergy of the two activities.
                            Initial laboratory studies appear promising in that the designed protein chimera can clear plaque in a low non-toxic dose.
                            We are currently testing the efficacy of the chimera via intranasal delivery.
                            If successful, this therapy will have significant clinical value and market potential.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    );
}