import './styles.css';

export default function Footer() {
    return (
        <footer id="footer">

            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h4>Join Our Newsletter</h4>
                            <p>Subscribe to our weekly newsletter to be up-to-date with our latest activities and researches.</p>
                            <form action="" method="post">
                                <input type="email" name="email" /><input type="submit" value="Subscribe" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h3><span>D</span>iverse <span>V</span>itality</h3>
                            <p>
                                933 San Mateo Blvd NE #500-227< br />
                                Albuquerque NM 87108 <br />
                                {/*933 San Mateo Blvd #500-227 <br />
                                ABQ NM 87108<br />*/}
                                United States <br /><br />
                                <strong>Phone:</strong> <br />
                                <div style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', columnGap: 5, paddingLeft: 15 }}>
                                    {/*<span>Steve Buelow:</span > <a href="tel:+1-505-695-4618">+1 505 209 2211</a> */}
                                    <span span > Goutam Gupta:</span> <a href="tel:+1-505-209-2211">+1 505 209 2211</a>
                                    <span>Vanessa Valore:</span> <a href="tel:+1-505-209-2211">+1 505 209 2211</a>
                                </div>
                                <strong>Email:</strong> contact@diversevitality.com<br />
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#about">About</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#impact">Impact</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Product Portfolio</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="#portfolio-plant">Plant Therapeutics</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#portfolio-animal">Treatment of animal diseases</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#portfolio-human">Human therapeutics for infectious diseases</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#portfolio-memory">Treatment of memory and brain disorders</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Social Networks</h4>
                            <p>Stay on touch with us through our various social media channels:</p>
                            <div className="social-links mt-3">
                                {/*<a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>*/}
                                <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                                <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                                <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                                <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div >

            <div className="container py-4">
                <div className="copyright">
                    &copy; Copyright <strong><span>Diverse Vitality</span></strong>. All Rights Reserved.
                </div>
                <div className="credits">
                    Designed by <a href="http://www.sandboxlife.com" target="_blank" rel="noreferrer">SandBoxLife</a>
                </div>
            </div>
        </footer >
    );
}