import { useState, useEffect } from "react";
import Preloader from "./components/Preloader/Preloader";
import Main from "./Main";

function App() {
	const [showPreloader, setShowPreloader] = useState(true);

	//useEffect(() => window.initAll, []);
	useEffect(() => {
		//window.allReady = true;
		window.initAll();
		setShowPreloader(false);
	}, []);

	return (
		<>
			{showPreloader && <Preloader />}
			<Main />
		</>
	);
}


export default App;
