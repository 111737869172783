import logo from '../../assets/img/logo.svg';
import NavBar from '../NavBar/NavBar';
import './styles.css';

export default function Header() {
    return (
        <header id="header" className="d-flex align-items-center">
            <div className="container d-flex align-items-center justify-content-between">

                {/*<h1 className="logo" id="logo1">
                    <a href="index.html" className='d-flex flex-column justify-items-start'>
                        <img src={logo} alt="logo" width='100%' height='100%' style={{ zoom: '470%', marginLeft: -20 }} />
                        {//<span style={{ fontSize: '45px' }}>D</span>iverse <span style={{ fontSize: '45px' }}>V</span>itality<span></span>}
                        {//<span style={{ fontSize: '15px', marginTop: -10, whiteSpace: 'nowrap' }}>Biodiverse Solutions</span>}
                    </a>
                </h1>*/}
                {/*<!-- Uncomment below if you prefer to use an image logo -->*/}
                <div className="logo">
                    <a href="#">
                        <img src={logo} alt="logo" width='100%' height='100%' />
                    </a>
                </div>

                <NavBar />
            </div>
        </header>
    );
}