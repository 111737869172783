import About from "./components/About/About";
import Supporters from "./components/Supporters/Supporters";
import Contact from "./components/Contact/Contact";
import Counts from "./components/Counts/Counts";
import Departments from "./components/Departments/Departments";
import Facility from "./components/Facility/Facility";
import FAQ from "./components/FAQ/FAQ";
import Features from "./components/Features/Features";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Impact from "./components/Impact/Impact";
import Mission from "./components/Mission/Mission";
import Portfolio from "./components/Portfolio/Portfolio";
import Pricing from "./components/Pricing/Pricing";
import Services from "./components/Services/Services";
import Pipeline from "./components/Pipeline/Pipeline";
import Team from "./components/Team/Team";
import Testimonials from "./components/Testimonials/Testimonials";
import TopBar from "./components/TopBar/TopBar";
import BackToTop from "./components/BackToTop/BackToTop";

export default function Main() {
    return (
        <>
            <TopBar />
            <Header />
            <Hero />

            <main id="main">
                <About />
                {/*<Mission />*/}
                <Pipeline />
                {/*<Counts />*/}
                <Supporters />
                {/*<Services />*/}
                <Impact />
                <Portfolio />
                <Team />
                {/*<Departments />*/}
                {/*<Features />*/}
                {/*<Testimonials />*/}
                {<Facility />}
                {/*<Pricing />*/}
                <FAQ />
                <Contact />
            </main>

            <Footer />

            <BackToTop />
        </>
    );
}