export default function Item({ data, seq }) {
    return (
        <li data-aos={seq % 2 ? "fade-right" : "fade-left"}>
            <div data-bs-toggle="collapse" className="collapsed question" href={`#faq${seq}`}>{data.title}<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
            <div id={`faq${seq}`} className="collapse" data-bs-parent=".faq-list">
                <p>
                    {data.body}
                </p>
            </div>
        </li>
    );
}