import $ from 'jquery';
import { ReactComponent as Plant } from '../../assets/img/plant.svg';
import { ReactComponent as Animal } from '../../assets/img/animal.svg';
import { ReactComponent as Human } from '../../assets/img/human.svg';
import { ReactComponent as Memory } from '../../assets/img/brain.svg';
import './styles.css';

export default function FeaturedServices() {
    /*const navTo = target => {
        console.log(`[data-bs-target="#tab-${target}"]`)
        console.log($(`[data-bs-target="#tab-${target}"]`)[0])
        //$(`[data-bs-target="#tab-${target}"]`)[0].tab('show');
        window.location.hash = `#portfolio-${target}`;
    };*/

    return (
        <section id="featured-services" className="featured-services">
            <div className="containerxx" data-aos="fade-up">

                <div className="rowxxxx d-flex flex-wrap justify-content-around">
                    <div className="xcol-md-6 xcol-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                        <a href="#portfolio" onClick={() => window.navTo('plant')}>
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon"><Plant /></div>
                                <h4 className="title"><a href="#portfolio">Plant</a></h4>
                                {/*<p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>*/}
                            </div>
                        </a>
                    </div>

                    <div className="xcol-md-6 xcol-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                        <a href="#portfolio" onClick={() => window.navTo('animal')}>
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon"><Animal /></div>
                                <h4 className="title"><a href="#portfolio">Animal</a></h4>
                                {/*<p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>*/}
                            </div>
                        </a>
                    </div>

                    <div className="xcol-md-6 xcol-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                        <a href="#portfolio" onClick={() => window.navTo('human')}>
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><Human /></div>
                                <h4 className="title"><a href="#portfolio">Human</a></h4>
                                {/*<p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>*/}
                            </div>
                        </a>
                    </div>

                    <div className="xcol-md-6 xcol-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                        <a href="#portfolio" onClick={() => window.navTo('memory')}>
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                                <div className="icon"><Memory /></div>
                                <h4 className="title"><a href="#portfolio">Memory</a></h4>
                                {/*<p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>*/}
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </section >
    );
}