export default function Memory() {
    return (
        <section id="portfolio-memory" className='pt-0'>
            <h5>Treatment of memory and brain disorders</h5>
            <br />

            <p>
                Associated with memory and brain disorders are impaired genes and proteins produced by brain cells
                (such as those encountered in Alzheimer’s Disease, Huntington’s Disease) or dysfunction of brain cells
                (as in Parkinson’s Disease).
                Initially, we focused on two pathologies commonly observed in AD, the formation of amyloid plaque and tau neurofibril.
                We identified two proteins for potential use for AD treatment:
                one that inhibits plaque formation and the other that cleaves the amyloid peptide.
                Both the proteins are under expressed in the presence of amyloid plaque.
                We modified these proteins to increase their anti-amyloid activities and formed a chimera by joining them.
                The chimeras appear to block plaque formation in <i>in vitro</i> studies.
            </p>

            <br />

            <div className="d-flex flex-wrap justify-content-around">
                <img src="assets/img/portfolio/ad01.png" className="m-1" style={{ width: '100%', maxWidth: 500, height: '100%', border: '0px solid black' }} alt="Healthy vs Alzheimer’s brain" />
                <img src="assets/img/portfolio/ad02.png" className="m-1" style={{ width: '100%', maxWidth: 700, height: '100%', border: '0px solid black' }} alt="Observation and solution" />
            </div>
        </section>
    );
}