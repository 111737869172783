export default function Member({ data }) {
    return (
        <div key={data.image} className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div className="member">
                <div className="member-img">
                    <img src={`assets/img/team/${data.image}`} className="img-fluid team-img" alt="" />
                    <div className="social">
                        {/*<a href="http://www.twitter.com/" target="_blank"><i className="bi bi-twitter"></i></a>*/}
                        <a href="http://www.facebook.com/" target="_blank"><i className="bi bi-facebook"></i></a>
                        <a href="http://www.instagram.com/" target="_blank"><i className="bi bi-instagram"></i></a>
                        <a href="http://www.linkedin.com/" target="_blank"><i className="bi bi-linkedin"></i></a>
                    </div>
                </div>
                <div className="member-info">
                    <h4>{data.name}</h4>
                    <span>{data.position}</span>
                </div>
                <div className="member-bio">
                    <p className="mx-2" dangerouslySetInnerHTML={{ __html: data.bio }}>
                    </p>
                </div>
            </div>
        </div>
    );
}