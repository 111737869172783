import { useMemo } from "react";
import Member from "./Member";

export default function Operation() {
    const legalFinance = useMemo(() => [
        {
            image: 'erin.jpg',
            name: 'Erin Kaiser',
            position: 'Chief Business Liaison',
            bio: `Erin has over 25 years of professional experience supporting Fortune 50 companies, GRAMMY-award winning musicians, and New York Times bestselling authors with meeting planning, project management, and marketing across six continents. Resourceful and adaptable by nature, Erin is keen to apply dynamic and engaged solutions to professional and personal endeavors and effortlessly leverages her vast network of contacts to help clients, colleagues, and friends alike fulfill their dreams and passions.`,

        },
        {
            image: 'brint.jpg',
            name: 'Brint Hiatt',
            position: 'Corporate Lawyer',
            bio: `The attorney will consult and handle all corporate legal processes (e.g., intellectual property, mergers & acquisitions, financial/securities offerings, compliance issues, transactions, agreements, lawsuits, patents) and will develop company policy and position on legal issues. Finally, he will research, anticipate, and guard company against legal risks and guide managers and ensure compliance with rules and regulations.`
        },
        {
            image: 'david.jpg',
            name: 'David S. Kerr',
            position: 'Patent Attorney',
            bio: `David S. Kerr is a partner and co-founder of Berg Hill Greenleaf Ruscitti’s Intellectual Property Practice Group (https://bhgrlaw.com/). His practice focuses on patent and trademark prosecution as well as intellectual property licensing and infringement litigation.  With a Master of Business Administration in addition to his law degree, David helps emerging companies aggressively manage their intellectual property portfolios and future product development efforts. David provides strategic counseling relating to patent and trade secret protection, diligence, transfer, acquisition, and licensing. David has experience protecting and licensing clients’ portfolios in the United States and more than 60 countries world-wide.  He has been assisting Goutam Gupta (Founder and Lead Scientist) in patent submission.`
        },
        {
            image: 'kimberly.jpg',
            name: 'Kimberly White',
            position: 'Accountant',
            bio: `Kimberly holds a bachelor’s degree in accounting from Wichita State University. She also has received her Master’s in Healthcare Administration from the University of Kansas. Out of school she joined Price Waterhouse where she excelled in every facet of the business.  After 3 years, she started her own company and has been instrumental to her company’s success and client retention. Her understanding of international tax handling for not only individuals but notable corporate clients, puts her in a unique class of advisors. She has a global understanding of how companies are run and an in-depth knowledge of financial statements, budgets, and tax projections.  Initially, Kimberly will handle all matters related to the budget and expenses of the company.`
        },
    ], []);

    return (
        <>
            {/*<h5>Legal and Financial Officers</h5>*/}
            <div className="row">
                {legalFinance.map((member, idx) => <Member key={idx} data={member} />)}
            </div>
        </>
    );
}