export default function Human() {
    return (
        <section id="portfolio-human" className='pt-0'>
            <h5>Human therapeutics for infectious diseases</h5>
            <br />

            <p>
                The platform technology developed for bacterial diseases in plants can also be applied to bacterial diseases in humans,
                for example, food poisoning due to E. coli and Salmonella and lung infection due to Pseudomonas.
                However, due to clinical urgency and market needs, our initial focus is on the diagnosis and treatment of Covid (CoV).
                We have designed a soluble ligand ACE2-hFc fusion protein to block the Ace2 recognition of the CoV spike protein,
                the first step in the CoV lifecycle.
            </p>

            <br />

            <div className="d-flex flex-wrap justify-content-center">
                <img id="Picture 8" src="assets/img/portfolio/covid01.png" style={{ width: '100%', maxWidth: 900, height: '100%', border: '0px solid black' }} alt="CoV lifecycle" />
            </div>

            <br />
            <br />

            <p>The Ace2-hFc fusion ligands were designed in 4 stages resulting in increases in spike protein binding and virus neutralizing activity.</p>

            <br />

            <div className="d-flex flex-wrap justify-content-center">
                <img src="assets/img/portfolio/covid02.png" style={{ width: '100%', maxWidth: 800, height: '100%', border: '0px solid black' }} alt="Ace2-hFc fusion ligands" />
            </div>

            <br />
            <br />

            <p>
                We are currently in the 3<sup>rd</sup> stage.
                The equilibrium dissociation constant K<sub>D</sub> for spike protein trimer binding is 10<sup>-11</sup>M and IC<sub>50</sub> for viral neutralization is 3nM.
            </p>

            <p>
                We envision a two-step diagnosis:
                first the binding of the virus to the ligand immobilized on the surface,
                and second sequencing of the eluted virus.
            </p>

            <div className="d-flex flex-wrap justify-content-center">
                <img id="Picture 10" src="assets/img/portfolio/covid03.png" style={{ width: '100%', maxWidth: 700, height: '100%', border: '0px solid black' }} alt="Two-step diagnosis" />
            </div>

            <br />
            <br />

            <p>The treatment mechanism will involve sequestering of the virus by the ligand and subsequent clearance by the effector cells via Fc receptors.</p>

            <br />

            <div className="d-flex flex-wrap justify-content-center">
                <img id="Picture 11" src="assets/img/portfolio/covid04.png" style={{ width: '100%', maxWidth: 900, height: '100%', border: '0px solid black' }} alt="Fc receptors" />
            </div>
        </section>
    );
}